// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.bp5-button.bp5-minimal {
    display:none;
}

button.bp5-button.bp5-minimal.bp5-intent-warning
{
    display:none;
}

svg#mdi-cloud-alert {
    display:none;
}

li:nth-child(7) {
    display:none;
}

body > div.bp5-portal > div > div.bp5-popover-transition-container.bp5-overlay-content.bp5-popover-appear-done.bp5-popover-enter-done > div > div.bp5-popover-content > ul > li:nth-child(7) > a {
    display:none;
}

body > div.bp5-portal > div > div.bp5-popover-transition-container.bp5-overlay-content.bp5-popover-enter-done > div > div.bp5-popover-content > ul > li:nth-child(7) > a {
    display:none;
}

button.bp5-button.bp5-fill.bp5-intent-success {
    display:none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["a.bp5-button.bp5-minimal {\n    display:none;\n}\n\nbutton.bp5-button.bp5-minimal.bp5-intent-warning\n{\n    display:none;\n}\n\nsvg#mdi-cloud-alert {\n    display:none;\n}\n\nli:nth-child(7) {\n    display:none;\n}\n\nbody > div.bp5-portal > div > div.bp5-popover-transition-container.bp5-overlay-content.bp5-popover-appear-done.bp5-popover-enter-done > div > div.bp5-popover-content > ul > li:nth-child(7) > a {\n    display:none;\n}\n\nbody > div.bp5-portal > div > div.bp5-popover-transition-container.bp5-overlay-content.bp5-popover-enter-done > div > div.bp5-popover-content > ul > li:nth-child(7) > a {\n    display:none;\n}\n\nbutton.bp5-button.bp5-fill.bp5-intent-success {\n    display:none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
